import cadcam1 from "../../images/sections/gallery/cadcam/photo_1_2023-03-10_10-04-56.jpg";
import cadcam2 from "../../images/sections/gallery/cadcam/photo_2_2023-03-10_10-04-56.jpg";
import cadcam3 from "../../images/sections/gallery/cadcam/photo_3_2023-03-10_10-04-56.jpg";
import cadcam4 from "../../images/sections/gallery/cadcam/photo_4_2023-03-10_10-04-56.jpg";
import cadcam5 from "../../images/sections/gallery/cadcam/photo_5_2023-03-10_10-04-56.jpg";
import cadcam6 from "../../images/sections/gallery/cadcam/photo_6_2023-03-10_10-04-56.jpg";
import cadcam7 from "../../images/sections/gallery/cadcam/photo_7_2023-03-10_10-04-56.jpg";
import cadcam8 from "../../images/sections/gallery/cadcam/photo_8_2023-03-10_10-04-56.jpg";
import cadcam9 from "../../images/sections/gallery/cadcam/photo_9_2023-03-10_10-04-56.jpg";
import cadcam10 from "../../images/sections/gallery/cadcam/photo_10_2023-03-10_10-04-56.jpg";
import cadcam11 from "../../images/sections/gallery/cadcam/photo_11_2023-03-10_10-04-56.jpg";
import cadcam12 from "../../images/sections/gallery/cadcam/photo_12_2023-03-10_10-04-56.jpg";
import cadcam13 from "../../images/sections/gallery/cadcam/photo_13_2023-03-10_10-04-56.jpg";
import cadcam14 from "../../images/sections/gallery/cadcam/photo_14_2023-03-10_10-04-56.jpg";


export const cadcam = [cadcam1, cadcam2, cadcam3, cadcam4, cadcam5, cadcam6, cadcam7, cadcam8, cadcam9, cadcam10, cadcam11, cadcam12, cadcam13, cadcam14];