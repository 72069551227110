import Layout from "../components/Layout";
import ChooseSection from "../components/sections/choose/ChooseSection";

import FounderSection from "../components/sections/founder/FounderSection";
import GallerySection from "../components/sections/gallery/GallerySection";

import HeroSection from "../components/sections/hero/HeroSection";
import NumbersSection from "../components/sections/numbers/NumbersSection";
import PopularSection from "../components/sections/popular/PopularSection";
import ReviewsSection from "../components/sections/reviews/ReviewsSection";
import StoreSection from "../components/sections/store/StoreSection";
import TrustedSection from "../components/sections/trusted/TrustedSection";

export default function SmileEnergyLanding() {
  return (
    <>
      <HeroSection />
      <NumbersSection />
      <ChooseSection />
      <TrustedSection />
      <ReviewsSection />
      <StoreSection />
      <PopularSection />
      <FounderSection />
      <GallerySection />
    </>
  );
}
