import senergy1 from '../../images/sections/gallery/senergy/IMG_2973.JPG';
import senergy2 from '../../images/sections/gallery/senergy/IMG_2974.JPG';
import senergy3 from '../../images/sections/gallery/senergy/IMG_2975.JPG';
import senergy4 from '../../images/sections/gallery/senergy/IMG_2976.JPG';
import senergy5 from '../../images/sections/gallery/senergy/IMG_2978.JPG';
import senergy6 from "../../images/sections/gallery/senergy/IMG_2982.JPG";
import senergy7 from "../../images/sections/gallery/senergy/IMG_2983.JPG";
import senergy8 from "../../images/sections/gallery/senergy/IMG_2985.JPG";
import senergy9 from "../../images/sections/gallery/senergy/IMG_2986.JPG";
import senergy10 from "../../images/sections/gallery/senergy/IMG_2987.JPG";
export const senergy = [
  senergy1,
  senergy2,
  senergy3,
  senergy4,
  senergy5,
  senergy6,
  senergy7,
  senergy8,
  senergy9,
  senergy10,
];