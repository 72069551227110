import contour1 from "../../images/sections/gallery/contour/IMG_0537.JPG";
import contour2 from "../../images/sections/gallery/contour/IMG_0990.JPG";
import contour3 from "../../images/sections/gallery/contour/IMG_1016.JPG";
import contour4 from "../../images/sections/gallery/contour/IMG_1021.PNG";
import contour5 from "../../images/sections/gallery/contour/IMG_1022.PNG";
import contour6 from "../../images/sections/gallery/contour/IMG_1836.PNG";
import contour7 from "../../images/sections/gallery/contour/IMG_1837.PNG";
import contour8 from "../../images/sections/gallery/contour/IMG_1838.PNG";
import contour9 from "../../images/sections/gallery/contour/IMG_2406.PNG";
import contour10 from "../../images/sections/gallery/contour/IMG_2408.PNG";


export const contour = [
  contour1,
  contour2,
  contour3,
  contour4,
  contour5,
  contour6,
  contour7,
  contour8,
  contour9,
  contour10,
];